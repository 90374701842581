import Vue from 'vue';

import {
  UPDATE_CHECKED_BANK_ID,
} from './mutations-types';

export default {
    [UPDATE_CHECKED_BANK_ID](state, payload) {
        Vue.set(state, 'checkedBankId', payload);
    },
}
