/* eslint-disable global-require,import/no-import-module-exports,
import/no-extraneous-dependencies */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  deleted, get, set, unset,
} from '../../../helpers/cookie';

import ChatRia from './ChatRia/index.vue';
import PromoAppSSI from './PromoAppSSI/index.vue';
import baseHref from '../../../helpers/baseHref';
import MultiAccounts from './MultiAccounts/index.vue';
import HeaderMessages from './HeaderMessages/index.vue';
import OneTapGoogle from '../OneTapGoogle/index.vue';
import LogoSSI from './LogoSSI/index.vue';
import AppInbox from './AppInbox/index.vue';
import _laxios from '../../../store/_laxios';
import SwitchingVersions from '../SwitchingVersions/SwitchingVersions.vue';

const PERIOD = 432000; // 5 днів

module.exports = {
  i18n: require('./i18n').default,
  mixins: [
    require('webpack:projectPath/vue/mixins/withData'),
    require('../../../mixins/exitLogger'),
    require('../../../mixins/loggerHeaderFooter'),
  ],
  components: {
    ChatRia,
    PromoAppSSI,
    MultiAccounts,
    HeaderMessages,
    OneTapGoogle,
    LogoSSI,
    AppInbox,
    SwitchingVersions,
    Popup: () => import('../Popup.vue'),
  },
  watch: {
    userData: {
      handler() {
        this.setNotificationsAndNotepad();
      },
      deep: true,
    },
    $route(to) {
      this.setUrl(to);
    },
  },
  beforeMount() {
    this.setNotificationsAndNotepad();
  },
  methods: {
    ...mapActions({
      fetchNewsSlonikInfo: 'news/fetchNewsSlonikInfo',
      clearNotifications: 'cabinet/notifications/clearNotifications',
      getUserData: 'Common/userData',
    }),
    ...mapMutations({
      setUrl: 'shared/url/current',
      makeAllViewed: 'cabinet/leftBar/makeAllViewed',
      decrementEmergencyCnt: 'cabinet/leftBar/decrementEmergencyCnt',
      decrementImportantCnt: 'cabinet/leftBar/decrementImportantCnt',
      decrementInformingCnt: 'cabinet/leftBar/decrementInformingCnt',
    }),
    setNotificationsAndNotepad() {
      if (this.userData.userId) {
        this.getNotepadData()
          .then((notepadItems = []) => {
            if (Array.isArray(notepadItems)) {
              this.notepad.count = notepadItems.length;
            }
          });

        this.getNotifications()
          .then((response) => {
            this.notifications.count = response.count;
            this.notifications.items = response.data;
          });
      }
    },
    clickOnMenuButton() {
      this.menu.visible = !this.menu.visible;
    },
    formLogoutUrl() {
      return `${this.baseHref}/?exit=yes&sourceId=20&screentype=${this.isMobile ? 2 : 1}`;
    },
    logout() {
      deleted('PSP_ID', '/', '.ria.com', 'Secure; SameSite=None; Partitioned;');
      unset('PHPSESSID');
      unset('jwt');
      this.loggerExit(20);
    },
    goToNotification(notificationCategory, gaParams) {
      let category = '';
      switch (notificationCategory) {
        case 1:
          category = 'actions';
          break;
        case 2:
          category = 'selling';
          break;
        case 3:
          category = 'buying';
          break;
        case 4:
          category = 'favorites';
          break;
        case 5:
          category = 'inform';
          break;
        default:
          category = '';
          break;
      }

      this.notificationsVisible = !this.notificationsVisible;

      this.$router.push(`${this.langPrefix}/cabinet/notifications/${category}`);
      if (gaParams) {
        this.gaEvent(gaParams.location, gaParams.element, gaParams.block);
      }
    },
    deleteNotification(notification, gaParams) {
      const data = {
        values: {
          is_deleted: true,
          is_viewed: true,
        },
        filter: {
          nc_notification_id: [notification.nc_notification_id],
        },
      };

      _laxios.notifications_PATCH.request({ data }).catch((e) => {
        console.error('ERROR deleteNotification', e.toString());
      });

      switch (notification.notification_type) {
        case 1:
          this.decrementEmergencyCnt();
          break;
        case 2:
          this.decrementImportantCnt();
          break;
        case 3:
          this.decrementInformingCnt();
          break;
      }

      this.notifications.items = this.notifications.items
        .filter((item) => Number(item.nc_notification_id) !== Number(notification.nc_notification_id));
      if (gaParams) {
        this.gaEvent(gaParams.location, gaParams.element, gaParams.block);
      }
    },
    deleteAllNotifications() {
      this.makeAllViewed();
      this.clearNotifications();
      this.notifications.items = [];
      this.notifications.count = 0;
    },
    openStat() {
      let customRMetrics = '';
      let parsedRMetrics = '';
      let refRMetrics = '';
      const validRMetrics = ['r_audience', 'r_source', 'r_medium', 'r_campaign', 'r_options'];
      const validBotSource = { tl: 'telegram', vb: 'viber', fb: 'facebook' };
      try {
        const referrerRiaCom = document.referrer.includes('ria.com');
        const rSearchString = window.location.search || window.location.hash.split('?')[1] || '';
        const rUrlParams = new URLSearchParams(rSearchString);
        if (validBotSource[rUrlParams.get('bot')]) {
          customRMetrics = `&r_audience=smm_buyers&r_campaign=ads_bu&r_medium=autobot&r_source=${validBotSource[rUrlParams.get('bot')]}`;
        }
        if (!customRMetrics) {
          rUrlParams.forEach((value, label) => {
            if (validRMetrics.includes(label)) parsedRMetrics += `&${label}=${value}`;
          });
        }
        if (!customRMetrics && !parsedRMetrics && !referrerRiaCom) {
          refRMetrics = document.referrer.length === 0
            ? '&r_audience=direct&r_source=direct'
            : `&r_audience=all&r_medium=referer&r_source=${new URL(document.referrer).host}`;
        }
      } catch (e) {
        console.error('=== ERROR in openStat ===', e.toString());
      }

      this.statOpenPage = false;
      return `/rMetrics?event_id=383&project_id=1&viewRandom=${Math.random()}${customRMetrics || parsedRMetrics || refRMetrics}`;
    },
    isHaveMultiAccounts() {
      const haveMultiAccounts = Boolean(localStorage.getItem('haveMultiAccounts'));
      if (haveMultiAccounts) {
        this.haveMultiAccounts = true;
      }
    },
    showLoginBar() {
      set('hideLoginCount', 1, { expires: PERIOD });
      this.notificationsVisible = false;
      this.isShowCount = false;
    },
    redirectToFD() {
      window.location.href = `${!this.userData?.userId ? `${this.langPrefix}/login.html?from_url=` : ''}${this.langPrefix}/add_auto.html`;
    },
    buttonAddCar(e) {
      e.preventDefault();
      this.gaEvent('MainPageNew', 'ADD_Auto', 'Header');
      this.redirectToFD();
    },
    buttonAddCarBurger(e) {
      e.preventDefault();
      this.gaEvent('MainPageNew', 'ADD_Auto', 'Burger');
      this.redirectToFD();
    },
  },
  created() {
    if (_TARGET_ === 'client' && !Number(get('hideLoginCount'))) {
      this.isShowCount = true;
    }
  },
  computed: {
    ssiVars() {
      return this.$store.state.ssiVars || {};
    },
    baseHrefWithoutLangCode() {
      const { base } = baseHref;
      return base;
    },
    baseHref() {
      return `${this.baseHrefWithoutLangCode}${this.langPrefix}`;
    },
    abbBtnHref() {
      return `${this.langPrefix}/add_auto.html`;
    },
    ...mapGetters({
      url: 'shared/url/current',
      userDataRaw: 'Common/userData',
      slonikInfo: 'news/slonikInfo',
      rand5: 'others/rand5',
      notificationCounters: 'cabinet/leftBar/notificationCounters',
      isWebView: 'shared/device/isWebView',
    }),
    commonNotificationsCounter() {
      return (this._result(this.notificationCounters, 'NotificationsEmergency.count') || 0) + (this._result(this.notificationCounters, 'NotificationsImportant.count') || 0) + (this._result(this.notificationCounters, 'NotificationsInforming.count') || 0);
    },
    slonikMarkaData() {
      const { slonikMarkaData } = this.slonikInfo || {};
      return slonikMarkaData;
    },
    userData() {
      return this.userDataRaw || {};
    },
    isSegmentForMultiAccounts() {
      const segment = this.userData.segment || '';
      const forSegments = ['smb', 'b2c'];
      const segmentType = segment.slice(0, 3);
      return forSegments.includes(segmentType) || this.haveMultiAccounts;
    },
    isMainPage() {
      return this.$route.name === 'mainPage';
    },
  },
  data() {
    return {
      menu: {
        visible: false,
      },
      active: false,
      notificationsVisible: false,
      userBlockIsVisible: false,
      notepad: {
        count: 0,
      },
      notifications: {
        count: 0,
        items: [],
      },
      statOpenPage: false,
      isShowCount: false,
      haveMultiAccounts: false,
      popupNotification: 0,
      popupNotificationArray: [0, 1, 2, 3],
    };
  },
  mounted() {
    this.isHaveMultiAccounts();
    this.getUserData({ withSegment: 1 });
    this.fetchNewsSlonikInfo({ ui: get('ui') });
    this.$root.$on('activeClass', () => {
      this.active = !this.active;
    });
    this.statOpenPage = true;
    this.popupNotification = this.popupNotificationArray[Math.floor(Math.random() * this.popupNotificationArray.length)];
  },
};
