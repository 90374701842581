import _laxios from './../../../../../../_laxios';

import { UPDATE_CHECKED_BANK_ID } from '../mutations/mutations-types';

export default {
  async fetchAuthCheckedBankId({commit}) {
    try {
      const response = await _laxios.getCheckedBankId.request();
      if (response && response.checkedBankId) {
        commit(UPDATE_CHECKED_BANK_ID, response.checkedBankId);
        return response;
      }
    } catch (e) {
      console.error('ERROR fetchAuthCheckedBankId', e);
      return null;
    }
  },
  async fetchBankIdAuthDeepLink(_, { redirectUrl } = {}) {
    try {
      return await _laxios.getDeepLinkBankId.request({params: {redirectUrl}});
    } catch (e) {
      console.error('ERROR fetchBankIdAuthDeepLink', e);
      return null;
    }
  }
};

